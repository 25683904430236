import React from 'react';
import { type AllTextualGenresType } from '../../../class/types/new-writing-hypothesi/textual-genre';
import './styles.scss';
import { FaCirclePlus } from 'react-icons/fa6';

interface SecretaryNewWritingHypothesisProps {
  textualGenre: AllTextualGenresType
  setSelected: (textualGenre: AllTextualGenresType) => void
  index: number
}

export default function SecretaryNewWritingHypothesisTableContent({textualGenre, setSelected, index}: SecretaryNewWritingHypothesisProps) {
  const returnDate = (date: string) => {
    if (!date) {
      return;
    }
    return date.split('/')[2];
  }

  return (
    <div className='secretary-new-writing-hypothesis-table-content'>
      <ul>
        <li>{index + 1}</li>
        <li>{textualGenre.roomName}</li>
        <li>{textualGenre.schoolName ? textualGenre.schoolName : '*'}</li>
        <li>{returnDate(textualGenre.date)}</li>
        <li>
          <FaCirclePlus className='plus-icon' onClickCapture={() => {
            setSelected(textualGenre);
          }}/>
        </li>
      </ul>
    </div>
  )
}
