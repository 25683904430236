/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/ban-types */
import React, { useState } from 'react';

import UpLoadElementarySchoolWeeklyPlanCostumer from '../../../class/UploadElementarySchoolSchoolWeeklytWork/upload-weekly-plan-custumer';
import { type ElementarySchoolDecentWorkType } from '../../../class/types/Upload_ElementarySchoolDecenteWork';
import { type UserClientProtocol } from '../../../class/types/User/UserAPI-protocol';
import { DisplayFileInfoTwo } from '../../../components/DisplayFile';
import { IoCloseOutline } from 'react-icons/io5';
import UserAPI from '../../../class/User/UserAPI';
import lscache from 'lscache';
import './style.scss';
import LoadContent from '../../../components/Load-content';

interface FormEditWeeklyPlanProps {
  setDisplayFormEditWeeklyPlan: React.Dispatch<React.SetStateAction<boolean>>
  setElementarySchoolWeeklyPlan: React.Dispatch<React.SetStateAction<ElementarySchoolDecentWorkType[]>>
  elementarySchoolWeeklyPlan: ElementarySchoolDecentWorkType[]
  setDisplayEditElement: React.Dispatch<React.SetStateAction<boolean>>
  displayFormEditWeeklyPlan: boolean
  elementId: string
}

export default function FormEditWeeklyPlan({ setDisplayFormEditWeeklyPlan, setElementarySchoolWeeklyPlan, displayFormEditWeeklyPlan, elementarySchoolWeeklyPlan, elementId }: FormEditWeeklyPlanProps): JSX.Element {
  const [fileInfo, setFileInfo] = useState<File | null>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [displayError, setDisplayError] = useState(false);
  const [isDisplayError, setIsDisplayError] = useState(false);

  const huddleCancel = () => {
    setDisplayFormEditWeeklyPlan(false);
  };

  const huddleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const form = e.target as HTMLFormElement;

    const inputs = form.querySelectorAll('input');

    const elementarySchoolWeeklyPlanCostumer = new UpLoadElementarySchoolWeeklyPlanCostumer(inputs, elementId);

    const user = lscache.get('user') as UserClientProtocol;

    if (!user || !user.id) {
      return;
    }

    const indexElement = elementarySchoolWeeklyPlan.findIndex((value) => elementId === value.id);

    if (typeof indexElement !== 'number') {
      setIsLoading(false);
      return;
    }

    const dayIsValid = verifyDay(elementarySchoolWeeklyPlan[indexElement].date);

    if (!dayIsValid) {
      setDisplayError(true);
      huddleDisplayError();
      return;
    }

    setIsLoading(true);
    elementarySchoolWeeklyPlanCostumer.put().then(response => {
      if (!response) {
        setIsLoading(false);
        return;
      }

      elementarySchoolWeeklyPlan[indexElement] = response;

      setElementarySchoolWeeklyPlan([...elementarySchoolWeeklyPlan]);

      setDisplayFormEditWeeklyPlan(false);
      setIsLoading(false);
    }).catch(e => {
      //
    });
  };

  const verifyDay = (date: string): Boolean => {
    const formatDate = {
      year: Number(date.split('-')[0]),
      month: Number(date.split('-')[1]) - 1,
      day: Number(date.split('-')[2]),
    }

    const validDates = [];

    for (let i = 0; i <= 2; i++) {
      validDates.push(new Date(formatDate.year, formatDate.month, formatDate.day + i))
    }

    let isValid = false;

    validDates.forEach(date => {
      if (new Date().toDateString() === date.toDateString()) {
        isValid = true;
      }
    });

    return isValid;
  }

  const inputFileEditHuddle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;

    if (!target.files?.item(0)) {
      return;
    }

    setFileInfo(target.files?.item(0));
  };

  const huddleDisplayError = () => {
    if (isDisplayError) {
      return;
    }
    setDisplayError(true);

    setIsDisplayError(true);
    setTimeout(() => {
      setIsDisplayError(false);
      setDisplayError(false);
    }, 5000);
  }

  return (
    <div className={`form-edit-elementary-weekly-plan display-${String(displayFormEditWeeklyPlan)}`}>
      <div className='header-form-add-element-content'>
        <h1>
          Editar Rotina Quinzenal
        </h1>
        <IoCloseOutline onClick={() => {
          setDisplayFormEditWeeklyPlan(false);
        }} size='25' />
      </div>
      <div className={`error-content display-${String(displayError)}`}>
        <h1>Período de edição expirado</h1>
      </div>
      <form method='POST' action='/' className='main-form-edit-monthly-plan' id='form-add-monthly-plan' onSubmit={huddleSubmit}>
        <div className="input-file-element">
          <label htmlFor="file-edit-input-elementary-school-weekly-plan">
            <DisplayFileInfoTwo file={fileInfo} />
          </label>
          <input
            type="file"
            id='file-edit-input-elementary-school-weekly-plan'
            onChangeCapture={inputFileEditHuddle}
            accept='.docx, vnd.openxmlformats-officedocument.wordprocessingml.document, .pdf, .jpeg, .jpg, .png'
          />
        </div>
        <div className='button-content'>
          <div>
            {isLoading
              ? (
                <LoadContent
                  isLoading={isLoading}
                  style={{
                    button: {
                      fontSize: 25,
                    },
                    container: {
                      width: 25,
                    }
                  }}
                />
              )
              : (
                <>
                  <button type='button' className='cancel-button' onClick={huddleCancel}>Cancelar</button>
                  <button type='submit' className='save-button'>Salvar</button>
                </>
              )}
          </div>
        </div>
      </form>
    </div>
  );
}
