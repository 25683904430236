
import { type SecretaryResourceManagementType, type DeleteResourceManagementResponseType } from '../types/secretary-resource-management/secretary-resource-management';
import { type UserProtocol } from '../types/User/User-factory-protocol';
import lscache from 'lscache';
import config from '../../config/config';
import axios from 'axios';

export default class SecretaryResourceManagementApi {
  private readonly user = lscache.get('user') as UserProtocol & {id: string};
	private get href() {
		return config.apiUrl;
	}

	constructor() {
		try {
			axios.defaults.headers.common.Authorization = `Bearer ${this.user.token ?? ''}`;
		} catch {
			//
		}
	}

  async post(title: string, date: string): Promise<SecretaryResourceManagementType> {
    const response = await axios.post(this.href + '/secretary/resource-management', {
      title,
      date,
      issueDate: this.getDate(),
      userName: this.user.userName ?? '',
      userId: this.user.id ?? ''
    });

    return response.data
  }

  async put(title: string, date: string, id: string): Promise<SecretaryResourceManagementType> {
    const response = await axios.put(this.href + `/secretary/resource-management/${id}`, {
      title,
      date,
    });

    return response.data;
  }

  async delete(id: string): Promise<DeleteResourceManagementResponseType> {
    const response = await axios.delete(this.href + `/secretary/resource-management/${id}`);

    return response.data;
  }

  async get(id: string): Promise<SecretaryResourceManagementType> {
    const response = await axios.get(this.href + `/secretary/resource-management/${id}`);

    return response.data;
  }

  async getAll() {
    const response = await axios.get(this.href + '/getall/secretary/resource-management');

    return response.data;
  }

  getDate(): string {
		const date = new Date();

		return date.toLocaleDateString('UTC', {
			dateStyle: 'short'
		});
	}
}
