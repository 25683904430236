import React, { useEffect, useState } from 'react';
import './styles.scss';
import InputSelect from '../../../../../components/input-select';
import { type twoMonthsType } from '../../../../../class/types/student-monetary-aid';
import { type AllTextualGenresType, type TextualGenreType } from '../../../../../class/types/new-writing-hypothesi/textual-genre';
import Chart from 'react-apexcharts';
import { type TagGraphType } from '..';

export interface SecretaryGraphElementProps {
  textualGenre: AllTextualGenresType
  addTagInformation: (tag: TagGraphType, index: number) => void
  deleteTextualGenreSelected: (index: number) => void
  addWritingTheNameCount: (count: number, index: number) => void
  resetTag: () => void
  index: number
}

const twoMonthsName: Array<{
  twoMonthsName: string
  twoMonths: twoMonthsType
}> = [
    {
      twoMonthsName: '1° Bimestre',
      twoMonths: 'twoMonths-one'
    },
    {
      twoMonthsName: '2° Bimestre',
      twoMonths: 'twoMonths-two'
    },
    {
      twoMonthsName: '3° Bimestre',
      twoMonths: 'twoMonths-tree'
    },
    {
      twoMonthsName: '4° Bimestre',
      twoMonths: 'twoMonths-for'
    }
  ]

export default function SecretaryGraphElements({ textualGenre, addTagInformation, addWritingTheNameCount, deleteTextualGenreSelected, resetTag, index }: SecretaryGraphElementProps): React.ReactElement {
  const [textualGenresNames, setTextualGenresNames] = useState<TextualGenreType[]>([]);
  const [series, setSeries] = useState<number[]>([]);
  const [colors, setColors] = useState<string[]>([]);
  const [labels, setLabels] = useState<string[]>([]);

  const [writingTheNameCount, setWritingTheNameCount] = useState(0);

  const [deleteInputTextualGenre, setDeleteInputTextualGenre] = useState(false);

  const cbInputSelect = (option: string) => {
    const twoMonthSelected = twoMonthsName.find(twoMonths => twoMonths.twoMonthsName.includes(option));
    resetTag();

    if (!twoMonthSelected) {
      return;
    }

    const textualGenreSelected = textualGenre.twoMonths[twoMonthSelected.twoMonths]

    if (!textualGenreSelected) {
      addWritingTheNameCount(0, index);
      setWritingTheNameCount(0);
      setTextualGenresNames([]);
      setSeries([]);
      setColors([]);
      setLabels([]);
      return;
    }

    const textualGenresNames = transformIntoArray(textualGenreSelected['textual-genre']);
    setTextualGenresNames(textualGenresNames);

    setSeries([]);
    setColors([]);
    setLabels([]);

    setDeleteInputTextualGenre(true);

    setTimeout(() => {
      setDeleteInputTextualGenre(false);
    }, 200);

    if (!textualGenreSelected.writingTheNameCount) {
      addWritingTheNameCount(0, index);
      setWritingTheNameCount(0);
      return;
    }

    setWritingTheNameCount(textualGenreSelected.writingTheNameCount);
    addWritingTheNameCount(textualGenreSelected.writingTheNameCount, index)
  }

  const transformIntoArray = (textualGenre: any) => {
    if (!textualGenre) {
      return [];
    }

    return Object.keys(textualGenre).map((key) => {
      return {
        id: key,
        ...textualGenre[key],
      }
    });
  }

  const cbTextualGenreSelect = (option: string) => {
    const tag = textualGenresNames.find((textualGenre) => textualGenre.genre.includes(option));
    resetTag();
    if (!tag) {
      return;
    }

    if (!tag['writing-hypothesi-result']) {
      return;
    }

    const series = tag['writing-hypothesi-result'].tags.map(tag => tag.count ?? 0)
    const colors = tag['writing-hypothesi-result'].tags.map(tag => tag.color)
    const labels = tag['writing-hypothesi-result'].tags.map(tag => tag.tag)

    setSeries(series)
    setColors(colors)
    setLabels(labels)

    addTagInformation({
      series, colors, labels
    }, index);
  }

  useEffect(() => {

  }, []);

  return (
    <div className='secretary-new-writing-hypothesis-graphs-elements'>
      <h1>{textualGenre.roomName}</h1>
      <div className='secretary-new-writing-hypothesis-graphs-elements-header'>
        <div className='secretary-new-writing-hypothesis-graphs-elements-header-input-content'>
          <InputSelect
            options={twoMonthsName.map(twoMonths => twoMonths.twoMonthsName)}
            title=''
            cb={cbInputSelect}
            defaultValue='Bimestres' />
          <InputSelect
            options={textualGenresNames.length === 0 ? [] : textualGenresNames.map(textualGenres => textualGenres.genre)}
            title=''
            cb={cbTextualGenreSelect}
            defaultValue='Gênero Textual'
            deleteAll={deleteInputTextualGenre}
          />
        </div>
        <button className='delete-button' onClickCapture={() => {
          deleteTextualGenreSelected(index);
          addWritingTheNameCount(0, index);
          setWritingTheNameCount(0);
        }}>Apagar</button>
      </div>
      {!writingTheNameCount
        ? ''
        : (
          <h1 className='writing-the-name-count-content'>Quantidade de aluno que escreve o nome: {writingTheNameCount}</h1>
        )}
      <div className='secretary-new-writing-hypothesis-graphs-elements-graph'>
        {series.length === 0
          ? ''
          : (
            <Chart
              className={'graphic-content'}
              type='pie'
              width={window.innerWidth < 550 ? 400 : 750}
              series={series}
              options={{
                labels,
                colors,
                legend: {
                  fontSize: `${window.innerWidth < 550 ? '8rem' : '16rem'}`,
                  offsetY: 70
                },
                stroke: {
                  show: true,
                  curve: 'smooth',
                  lineCap: 'butt',
                  colors: ['#000000'],
                  width: 0.5,
                  dashArray: 0
                },
                chart: {
                  width: '50px',
                  height: '50px'
                },
                title: {
                  align: 'center',
                  margin: 30,
                  style: {
                    fontSize: `${window.innerWidth < 550 ? '1rem' : '2rem'}`,
                  },
                  text: `Hipótese de Escrita ${textualGenre.roomName}`,
                }
              }}
            />
          )}
      </div>
    </div>
  )
}
