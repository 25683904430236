import React, { useEffect, useState } from 'react';
import './styles.scss';
import { type StudentWritingLevelType } from '../../../class/types/new-writing-hypothesi/new-writing-hypothesis';
import InputSelect from '../../input-select';
import { type TextualGenreTagType, type TextualGenreType } from '../../../class/types/new-writing-hypothesi/textual-genre';
import NewWritingHypothesisApi from '../../../class/new-writing-hypothesi/new-writing-hypothesis/new-writing-hypothesis-api';
import { useParams } from 'react-router-dom';
import LoadContent from '../../Load-content';
import { IoIosArrowDown } from 'react-icons/io';
import lscache from 'lscache';

interface NewWritingHypothesisProps {
  index: number
  roomInfo: any
  textualGenre: TextualGenreType
  studentWritingLevel: StudentWritingLevelType
  setTextualGenresSelected: React.Dispatch<React.SetStateAction<TextualGenreType | null>>
  setTextualGenres: React.Dispatch<React.SetStateAction<TextualGenreType[]>>
}

export default function NewWritingHypothesisElement({ index, roomInfo, textualGenre, studentWritingLevel, setTextualGenres, setTextualGenresSelected }: NewWritingHypothesisProps): React.ReactElement {
  const [isLoading, setIsLoading] = useState(false);
  const [option, setOption] = useState(studentWritingLevel.writingLevel.tag ?? '');
  const [displayOption, setDisplayOption] = useState(false);
  const { id } = useParams();

  const ruleClient = lscache.get('rule') as string;

  const selectCb = (li: React.MouseEvent) => {
    if (!['console', 'coordenador'].includes(ruleClient)) {
      return;
    }

    if (!textualGenre.tags) {
      setDisplayOption(!displayOption);
      return
    }

    const target = li.target as HTMLLIElement;

    const liTextContent = target.textContent ?? '';

    setOption(liTextContent);

    const tags = Object.keys(textualGenre.tags).map((key) => {
      return {
        id: key,
        ...textualGenre.tags[key]
      }
    });

    let tagSelected: {
      id?: string
      tag?: string
      color?: string
    } | undefined;

    tags.forEach(tag => {
      if (tag.tag.includes(liTextContent)) {
        tagSelected = tag as TextualGenreTagType;
      }
    });

    if (!tagSelected) {
      tagSelected = {
        tag: 'NR'
      }
    }

    const newWritingHypothesisApi = new NewWritingHypothesisApi();

    if (!id) {
      return;
    }

    if (!tagSelected) {
      return;
    }

    setDisplayOption(false);

    setIsLoading(true);
    newWritingHypothesisApi.postWritingHypothesis(id, roomInfo.twoMonths, textualGenre.id, studentWritingLevel.id, tagSelected).then(res => {
      setTextualGenres(res);

      res.forEach(t => {
        if (t.id === textualGenre.id) {
          setTextualGenresSelected(textualGenre);
          setIsLoading(false);
        }
      })
    }).catch(e => {
      //
    })
  }

  useEffect(() => {
    setOption(studentWritingLevel.writingLevel.tag);
  }, [studentWritingLevel]);

  const huddleDisplayOption = () => {
    setDisplayOption(!displayOption);
  }
  return (
    <div className='new-writing-hypothesis-element'>
      <ul className='new-writing-hypothesis-element-ul'>
        <li>{index + 1}</li>
        <li>{studentWritingLevel.name}</li>
        <li>
          {isLoading
            ? (
              <LoadContent
                isLoading={isLoading}
                style={
                  {
                    button: {
                      fontSize: 20,
                    },
                    container: {
                      width: 20,
                    }
                  }
                }
              />
            )
            : ''}
          <div className='input-select-new-writing-hypothesis'>
            <div className='input-select-new-writing-hypothesis-input-content' onClick={huddleDisplayOption}>
              <input type="text" value={option} disabled />
              <IoIosArrowDown className={`icon-arrow display-${String(displayOption)}`} size={20} />
            </div>
            <div className={`input-select-new-writing-hypothesis-options display-${String(displayOption)}`}>
              <ul>
                {!textualGenre.tags
                  ? ' '
                  : Object.keys(textualGenre.tags).map((key) => {
                    return <li onClick={selectCb} key={textualGenre.tags[key].id}>{textualGenre.tags[key].tag}</li>
                  })
                }
                <li onClick={selectCb}>NR</li>
              </ul>
            </div>
          </div>
        </li>
      </ul>
    </div>
  )
}
