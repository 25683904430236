/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable no-return-assign */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { useEffect, useState } from 'react';
import './styles.scss';
import { type AllTextualGenresType } from '../../../../class/types/new-writing-hypothesi/textual-genre';
import SecretaryGraphElements from './graphs-elements';
import Chart from 'react-apexcharts';
import formatText from '../../../../services/formtText';
import SaveAlert from '../../../../components/save-alert';

export interface SecretaryNewWritingHypothesisGraphsProps {
  display: boolean
  setDisplay: React.Dispatch<React.SetStateAction<boolean>>
  deleteTextualGenreSelected: (index: number) => void
  textualGenreSelected: AllTextualGenresType[]
}

export interface TagGraphType {
  labels: string[]
  series: number[]
  colors: string[]
}

export default function SecretaryNewWritingHypothesisGraphs({ display, setDisplay, textualGenreSelected, deleteTextualGenreSelected }: SecretaryNewWritingHypothesisGraphsProps): React.ReactElement {
  const [tags, setTags] = useState<TagGraphType[]>([]);
  const [writingTheNamesCount, setWritingTheNamesCount] = useState<number[]>([0]);
  const [tag, setTag] = useState<TagGraphType>();
  const [displayAlert, setDisplayAlert] = useState(false);

  const addTagInformation = (tag: TagGraphType, index: number) => {
    tags[index] = tag;

    setTags([...tags]);
  }

  const addWritingTheNameCount = (count: number, index: number) => {
    writingTheNamesCount[index] = count;

    setWritingTheNamesCount([...writingTheNamesCount]);
  }

  const generateGraph = () => {
    const acumulador = {
      series: [],
      colors: [],
      labels: [],
    };

    if (!tags[0]) {
      if (displayAlert) {
        return;
      }

      setDisplayAlert(true);
      setTimeout(() => {
        setDisplayAlert(false);
      }, 3000);
      return;
    }

    const copy = [...tags];

    const tag = copy.reduce((previousValue, currentValue): any => {
      const newPreviousValue = {
        series: [...previousValue.series],
        colors: [...previousValue.colors],
        labels: [...previousValue.labels],
      };

      if (newPreviousValue.labels.length === 0) {
        return currentValue;
      }

      newPreviousValue.labels.forEach((label, index) => {
        currentValue.labels.forEach((labelCurrentValue, indexCurrentValue) => {
          if (formatText(labelCurrentValue) === formatText(label)) {
            newPreviousValue.series[index] += currentValue.series[indexCurrentValue];
          }
        });
      });

      return newPreviousValue;
    }, acumulador);

    setTag(tag);
  }

  const resetTag = () => {
    setTag(undefined);
  }

  return (
    <div className={`secretary-new-writing-hypothesis-graphs display-${display}`}>
      <SaveAlert display={displayAlert} setDisplay={setDisplayAlert}>
        O primeiro gráfico não foi definido
      </SaveAlert>
      <div className='secretary-new-writing-hypothesis-graphs-header'>
        <h1>Gráficos - Hipóteses de Escrita Secretaria </h1>
        <button className='button-content' onClickCapture={() => { setDisplay(!display) }}>Voltar</button>
      </div>
      <div className='graph-content'>
        {textualGenreSelected.map((MapTag, index) => {
          return <SecretaryGraphElements deleteTextualGenreSelected={deleteTextualGenreSelected} addTagInformation={addTagInformation} addWritingTheNameCount={addWritingTheNameCount} textualGenre={MapTag} key={MapTag.roomId} index={index} resetTag={resetTag} />
        })}
      </div>
      <div className='secretary-new-writing-hypothesis-graphs-generate-graph-button-content'>
        <button className='button-content' onClickCapture={generateGraph}>Gerar Gráfico da Secretaria</button>
      </div>
      <div className='secretary-graph-content'>
        {!tag
          ? ''
          : (
            <Chart
              className={'graphic-content'}
              type='pie'
              width={window.innerWidth < 550 ? 400 : 750}
              series={tag?.series ?? []}
              options={{
                labels: tag?.labels ?? [],
                colors: tag?.colors ?? [],
                legend: {
                  fontSize: `${window.innerWidth < 550 ? '8rem' : '16rem'}`,
                  offsetY: 70
                },
                stroke: {
                  show: true,
                  curve: 'smooth',
                  lineCap: 'butt',
                  colors: ['#000000'],
                  width: 0.5,
                  dashArray: 0
                },
                chart: {
                  width: '50px',
                  height: '50px'
                },
                title: {
                  align: 'center',
                  margin: 30,
                  style: {
                    fontSize: `${window.innerWidth < 550 ? '1rem' : '2rem'}`,
                  },
                  text: `Hipótese de Escrita - Secretaria de Educação: Salas - ${textualGenreSelected.length}`
                }
              }}
            />
          )}
      </div>
      {!tag
        ? ''
        : (
          <div className={'new-writing-hypothesi-graph-content-info'}>
            <h1>Quantidade de alunos que foram avaliados: {tag?.series.reduce((prev, value) => prev += value, 0)}</h1>
            <h1>Quantidade de alunos que escrevem o nome {writingTheNamesCount?.reduce((prev, value) => prev += value, 0)}</h1>
            <ul className='new-writing-hypothesi-graph-content-info-list'>
              {tag?.labels.map((label, index) => {
                return <li key={''}>
                  <div style={{
                    position: 'relative',
                    content: '',
                    backgroundColor: tag.colors[index],
                    width: 20,
                    height: 20,
                    borderRadius: '100%',
                    border: '2px solid black',
                  }}></div> <span>{label}: {tag.series[index]}</span>
                </li>
              })}
            </ul>
          </div>
        )
      }
    </div>
  )
}
