import React, { useEffect, useState } from 'react';
import './styles.scss';
import SearchElement from '../../../../components/Search-element';
import TextualGenreApi from '../../../../class/new-writing-hypothesi/textual-genre/textual-genre-api';
import { type AllTextualGenresType } from '../../../../class/types/new-writing-hypothesi/textual-genre';
import SecretaryNewWritingHypothesisTableContent from '../../../../components/new-writing-hypothesis/secretary-new-writing-hypothesis';
import { MdAutoGraph } from 'react-icons/md';
import SecretaryNewWritingHypothesisGraphs from '../graphs';

interface NewSecretaryWritingHypothesisTableProps {
  display: string
}

export default function NewSecretaryWritingHypothesisTable({ display }: NewSecretaryWritingHypothesisTableProps): React.ReactElement {
  const [allTextualGenres, setAllTextualGenre] = useState<AllTextualGenresType[]>([]);
  const [elementFiltered, setElementFiltered] = useState<AllTextualGenresType[]>([]);

  const [textualGenreSelected, setTextualGenreSelected] = useState<AllTextualGenresType[]>([]);

  const [displayGraphs, setDisplayGraphs] = useState<boolean>(false);

  const returnDate = (date: string) => {
    return String((new Date(date)).getFullYear());
  }

  const returnTextualGenreDateRefactoring = () => {
    return allTextualGenres.map((textualGenre) => {
      return {
        ...textualGenre,
        date: returnDate(textualGenre.date),
      }
    });
  }

  const setSelected = (textualGenre: AllTextualGenresType) => {
    let isSelected = false;
    textualGenreSelected.forEach(value => {
      if (textualGenre.roomId === value.roomId) {
        isSelected = true;
      }
    });

    if (isSelected) {
      return;
    }

    setTextualGenreSelected([
      ...textualGenreSelected,
      textualGenre
    ]);
  }

  const deleteTextualGenreSelected = (index: number) => {
    textualGenreSelected.splice(index, 1)
    setTextualGenreSelected([...textualGenreSelected]);
  }

  useEffect(() => {
    if (display === 'false' || allTextualGenres.length > 0) {
      return;
    }

    const textualGenre = new TextualGenreApi();

    textualGenre.getAllTextualGenre().then((response) => {
      setAllTextualGenre(response);
      setElementFiltered(response);
    }).catch(e => {
      //
    });
  }, [display])

  return (
    <div className="table-new-secretary-writing-hypothesis">
      <SecretaryNewWritingHypothesisGraphs
        textualGenreSelected={textualGenreSelected}
        display={displayGraphs}
        setDisplay={setDisplayGraphs}
        deleteTextualGenreSelected={deleteTextualGenreSelected}
      />
      <div className='new-secretary-writing-hypothesis-table-header'>
        <h1>Registro de Nível de Escrita</h1>
      </div>
      <div className='new-secretary-writing-hypothesis-middle-content'>
        <SearchElement elementFiltered={elementFiltered} elementKey='roomName' elements={allTextualGenres} setElementFiltered={setElementFiltered} placeholder='Nome da Sala' />
        <SearchElement elementFiltered={elementFiltered} elementKey='date' elements={returnTextualGenreDateRefactoring()} setElementFiltered={setElementFiltered} placeholder='Ano' removeRepeat={true} />
        <h1 onClickCapture={() => { setDisplayGraphs(!displayGraphs) }}>
          <MdAutoGraph className='icon-graph' /> {!textualGenreSelected ? '0' : textualGenreSelected.length}
        </h1>
      </div>
      <div className='new-secretary-writing-hypothesis-table-content'>
        <div className='new-secretary-writing-hypothesis-table-content-header'>
          <ul>
            <li>Nº</li>
            <li>Nome da Sala</li>
            <li>Escola</li>
            <li>Ano</li>
            <li></li>
          </ul>
        </div>
        <div className='new-secretary-writing-hypothesis-table-content-list'>
          {elementFiltered.map((textualGenre, index) => (
            <SecretaryNewWritingHypothesisTableContent
              setSelected={setSelected}
              textualGenre={textualGenre}
              index={index}
              key={textualGenre.roomId}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
